type Item = [string, string];

export class Attributes {
  private items: Item[];

  constructor() {
    this.items = [];
  }

  add(key: string, value: string): void {
    this.items.push([key, value]);
  }

  delete(key: string): void {
    const indexToDelete = this.items.findIndex((value) => value[0] === key);

    if (indexToDelete === -1) return;

    this.items = this.items.filter((_, index, __) => index !== indexToDelete);
  }

  clear(): void {
    this.items = [];
  }

  get all(): Item[] {
    return this.items;
  }
}
