import { toggleEditMode } from "./EditMode";
import { renderValues } from "./RenderValues";
import { initCrop } from "./Crop";
import html2canvas from "html2canvas";
import { Attributes } from "./Attributes";
import {
  addAttributeToForm,
  renderForm,
  updateAttributes,
} from "./AttributesForm";
import { trackEvent } from "./Analytics";

const toggleEditModeButtons = document.querySelectorAll(
  ".preview-button, .edit-button"
);
toggleEditMode(toggleEditModeButtons);

const inputs: NodeListOf<HTMLInputElement> =
  document.querySelectorAll("input, textarea");
renderValues(inputs);

const nodeToDownload = document.querySelector(".container");
const downloadButtons = document.querySelectorAll(".download-button");
downloadButtons.forEach((btn) => {
  btn.addEventListener("click", () => {
    const classList = document.body.classList;
    const isEditMode = classList.contains("edit-mode");
    if (isEditMode) {
      classList.remove("edit-mode");
    }
    classList.toggle("downloading");
    // @ts-ignore
    html2canvas(nodeToDownload).then((canvas) => {
      if (isEditMode) {
        classList.add("edit-mode");
      }
      classList.toggle("downloading");
      const link = document.createElement("a");
      const date = new Date();
      link.download = `petresume.online_${date.getTime()}.jpg`;
      link.href = canvas.toDataURL("image/png;base64");
      trackEvent("download", "click", "dog", () => link.click());
    });
  });
});

const petPictureInput: HTMLInputElement = document.getElementById(
  "pet--upload-picture-input"
) as HTMLInputElement;
initCrop(petPictureInput);

let attributes = new Attributes();
if (window.localStorage.getItem("attributes")) {
  for (const [key, value] of JSON.parse(
    window.localStorage.getItem("attributes")
  )) {
    attributes.add(key, value);
  }
} else {
  const attributeElements = document.querySelectorAll(".pet-info--text--item");
  attributeElements.forEach((e) => {
    attributes.add(
      e.firstElementChild.textContent,
      e.lastElementChild.textContent
    );
  });
}

const attributesForm: HTMLFormElement = document.getElementById(
  "attributes-form"
) as HTMLFormElement;
const modalButton = document.getElementById("pet-info-green-title-input");
const modal = document.getElementById(modalButton.dataset.modal);
const exits = modal.querySelectorAll(".modal-exit");

modalButton.addEventListener("click", (event) => {
  event.preventDefault();
  renderForm(attributesForm, attributes);
  modal.classList.add("open");
});

exits.forEach((exit) => {
  exit.addEventListener("click", (event) => {
    event.preventDefault();
    modal.classList.remove("open");
  });
});

const addAttributeButton = document.querySelector(
  "#attributes-modal-form .add-attribute-button"
);
addAttributeButton.addEventListener("click", (e) => {
  e.preventDefault();
  addAttributeToForm(attributesForm);
});

const renderAttributes = (
  attributes: Attributes,
  attrsContainerEl: Element
) => {
  const attrElements = [];

  for (const [key, value] of attributes.all) {
    if (!key && !value) {
      continue;
    }
    const containerEl = document.createElement("span");
    containerEl.classList.add("pet-info--text--item");

    const keyEl = document.createElement("span");
    keyEl.textContent = key;

    const arrowEl = document.createElement("img");
    arrowEl.src = require("../img/arrow.svg");
    arrowEl.classList.add("pet-green__arrow", "noselect");
    arrowEl.alt = "arrow";
    arrowEl.width = 19;
    arrowEl.height = 9;

    const valueEl = document.createElement("span");
    valueEl.textContent = value;

    containerEl.append(keyEl, " ", arrowEl, " ", valueEl);
    attrElements.push(containerEl);
    attrElements.push(" ");
  }

  attrsContainerEl.innerHTML = "";
  attrsContainerEl.append(...attrElements);
};

const attributesContainerEl = document.querySelector(
  ".pet-info--text__no-title"
);
renderAttributes(attributes, attributesContainerEl);

const saveAttributesButton = document.querySelector(
  "#attributes-modal-form .save-button"
);
saveAttributesButton.addEventListener("click", (e) => {
  e.preventDefault();
  updateAttributes(attributesForm, attributes);
  renderAttributes(attributes, attributesContainerEl);
  window.localStorage.setItem("attributes", JSON.stringify(attributes.all));
});

document
  .querySelectorAll(".current-year")
  .forEach((e) => (e.innerHTML = new Date().getFullYear().toString()));

const togglePetPicture = (): void => {
  const petPicture: HTMLImageElement = document.querySelector(".pet--picture");
  if (petPicture.classList.contains("dog-picture")) {
    petPicture.classList.remove("dog-picture");
    petPicture.classList.add("cat-picture");
    petPicture.src = require("../img/cat.svg");
    return;
  }

  if (petPicture.classList.contains("cat-picture")) {
    petPicture.classList.remove("cat-picture");
    petPicture.classList.add("dog-picture");
    petPicture.src = require("../img/dog.svg");
  }
};

document.querySelector(".switch").addEventListener("click", (): void => {
  document.body.classList.toggle("dog-theme");
  document.body.classList.toggle("cat-theme");
  togglePetPicture();
});

document.querySelector(".switch-cat").addEventListener("click", (): void => {
  document.body.classList.remove("dog-theme");
  document.body.classList.add("cat-theme");
  togglePetPicture();
});

document.querySelector(".switch-dog").addEventListener("click", (): void => {
  document.body.classList.remove("cat-theme");
  document.body.classList.add("dog-theme");
  togglePetPicture();
});
