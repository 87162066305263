export const createFnWithTimeout = (
  callback: () => void,
  timeout: number
): (() => void) => {
  let called = false;

  const fn = (): void => {
    if (called) return;

    called = true;
    callback();
  };

  setTimeout(fn, timeout);

  return fn;
};

export const trackEvent = (
  name: string,
  action: string,
  label: string,
  callback?: () => void,
  timeout: number = 500
): void => {
  if (callback !== undefined) {
    window.ga("send", "event", name, action, label, {
      hitCallback: createFnWithTimeout(callback, timeout),
    });
  } else {
    window.ga("send", "event", name, action, label);
  }
};
