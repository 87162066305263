export default class FormCache {
  set(key: string, value: string): void {
    return localStorage.setItem(key, value);
  }

  has(key: string): boolean {
    return localStorage.getItem(key) !== null;
  }

  get(key: string): string {
    return localStorage.getItem(key);
  }

  clear(): void {
    return localStorage.clear();
  }
}
