export const toggleEditMode = (
  buttons: NodeListOf<Element>,
  className: string = "edit-mode"
) => {
  buttons.forEach((btn) =>
    btn.addEventListener("click", () =>
      document.body.classList.toggle(className)
    )
  );
};
