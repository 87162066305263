import Croppie from "croppie";

export const initCrop = (petPictureInput: HTMLInputElement): void => {
  const labelEls = petPictureInput.labels;
  for (const labelEl of labelEls) {
    labelEl.addEventListener("click", (e: Event): void => {
      if (!document.body.classList.contains("edit-mode")) {
        e.preventDefault();
      }
    });
  }

  petPictureInput.addEventListener("change", (): void => {
    if (!petPictureInput.files.length) {
      return;
    }
    const petPictureFile = petPictureInput.files[0];
    const imgEl = document.createElement("img");
    imgEl.src = URL.createObjectURL(petPictureFile);
    imgEl.classList.add("pet--picture", "noselect");
    imgEl.width = 170;
    imgEl.height = 170;
    imgEl.alt = "pet picture";
    imgEl.onload = () => URL.revokeObjectURL(imgEl.src);
    const oldImgEl = document.querySelector(".pet--picture");
    const labelEl = oldImgEl.parentNode;
    oldImgEl.replaceWith(imgEl);
    labelEl.addEventListener("click", (e: Event): void => {
      if (
        labelEl.querySelector(".croppie-container") ||
        (e.target as Element).classList.contains("pet--picture-crop-button") ||
        !document.body.classList.contains("edit-mode")
      ) {
        e.preventDefault();
      }
    });

    const croppie = new Croppie(imgEl, {
      viewport: { width: 170, height: 170, type: "circle" },
      boundary: { width: 240, height: 240 },
      showZoomer: true,
    });
    const cropButtonEl = document.createElement("a");
    cropButtonEl.innerText = "Crop";
    cropButtonEl.classList.add(
      "pet--picture-crop-button",
      "button",
      "button-blue"
    );
    imgEl.parentNode.appendChild(cropButtonEl);
    document.body.classList.toggle("crop-mode");

    cropButtonEl.addEventListener("click", (): void => {
      croppie.result({ type: "base64" }).then((blob: string): void => {
        imgEl.src = blob;
        document.body.classList.toggle("crop-mode");
        croppie.destroy();
        petPictureInput.value = "";
        cropButtonEl.remove();
      });
    });
  });
};
