import { Attributes } from "./Attributes";

export const renderForm = (
  form: HTMLFormElement,
  attributes: Attributes
): void => {
  form.innerHTML = "";
  for (const [key, value] of attributes.all) {
    addAttributeToForm(form, key, value);
  }
};

export const addAttributeToForm = (
  form: HTMLFormElement,
  key?: string,
  value?: string
): void => {
  const index = form.querySelectorAll("div").length;
  const div = document.createElement("div");
  const keyInput = document.createElement("input");
  div.appendChild(keyInput);
  keyInput.classList.add("attribute-input", "attribute-key-input");
  keyInput.name = `attributes[${index}][key]`;
  if (key) {
    keyInput.value = key;
  }
  keyInput.placeholder = "Attribute name, e.g., Age";
  keyInput.autocomplete = "off";

  const valueInput = document.createElement("input");
  div.appendChild(valueInput);
  valueInput.classList.add("attribute-input", "attribute-value-input");
  valueInput.name = `attributes[${index}][value]`;
  if (value) {
    valueInput.value = value;
  }
  valueInput.placeholder = "Attribute value, e.g., 1 year";
  valueInput.autocomplete = "off";

  const deleteButton = document.createElement("img");
  div.appendChild(deleteButton);
  deleteButton.classList.add("attribute-delete-input", "noselect");
  deleteButton.width = 36;
  deleteButton.height = 36;
  deleteButton.alt = "delete icon";
  deleteButton.src = require("../img/delete.svg");
  deleteButton.addEventListener("click", () => div.remove());

  form.append(div);
};

export const updateAttributes = (
  form: HTMLFormElement,
  attributes: Attributes
): void => {
  let attributeKey: string | null;
  attributes.clear();
  const values: string[] = [].map.call(
    form.querySelectorAll("input"),
    (e: HTMLInputElement) => e.value
  );
  for (const inputValue of values) {
    if (!attributeKey) {
      attributeKey = inputValue;
      continue;
    }

    attributes.add(attributeKey, inputValue);
    attributeKey = null;
  }
};
