import FormCache from "./FormCache";

const getValue = (name: string): HTMLElement | null =>
  document.getElementById(name.replace("-input", ""));

const renderValue = (name: string, value: string): void => {
  getValue(name).innerText = value;
};

const renderInput = (input: HTMLInputElement) => {
  renderValue(input.id, input.value || input.placeholder);
  if (input.nodeName == "TEXTAREA") {
    input.style.height = `${getValue(input.id).clientHeight - 6}px`;
  }
};

export const renderValues = (inputs: NodeListOf<HTMLInputElement>): void => {
  const formCache = new FormCache();
  Array.prototype.forEach.call(inputs, (input: HTMLInputElement) => {
    if (!getValue(input.id)) {
      return;
    }
    if (formCache.has(input.id)) {
      input.value = formCache.get(input.id);
    }
    renderInput(input);
    input.addEventListener("keyup", () => {
      formCache.set(input.id, input.value);
      renderInput(input);
    });
  });

  window.addEventListener("resize", () => {
    Array.prototype.forEach.call(inputs, (input: HTMLInputElement) => {
      if (!getValue(input.id)) {
        return;
      }
      renderInput(input);
    });
  });
};
